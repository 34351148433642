export const BREAKPOINT_VALUES = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1440,
  xl: 1530,
};

export const MENU_WIDTH = {
  expanded: 270,
  collaped: 80,
  collapedMobile: 52,
};

export const AUTO_PLAY_ITEM_TIME = 5000;

export const MAX_NOTI_CHAR_QLT = 99;

export const MAX_CHAT_CHARACTER_LEN = 500;

export const DIFF_DAY_TO_SHOW_TIP_RFQ_CATE = 14;

export const SM_LISTING_BREAKPOINT = {
  480: {
    slidesPerView: 1,
  },
  640: {
    slidesPerView: 5,
    spaceBetween: 8,
  },
};

export const COLORS = {
  green: {
    25: "#EDFFF4",
    50: "#E3FFEE",
    500: "#019c3c",
    600: "#008934",
    700: "#008934",
    800: "#025020",
  },
  brand: {
    25: "#edfff4",
    500: "#019c3c",
    700: "#027b30",
  },
  gray: {
    50: "#f9fafb",
    100: "#f2f4f7",
    200: "#EEEEEE",
    300: "#d0d5dd",
    400: "#98A2B3",
    500: "#667085",
    600: "#475467",
    700: "#344054",
    800: "#1d2939",
    900: "#101828",
  },
  bGray: {
    50: "#f8f9fc",
    500: "#4e5ba6",
    600: "#3e4784",
  },
  yellow: {
    25: "#fffaeb",
    100: "#fef0c7",
    500: "#f79009",
    600: "#DC6803",
  },
  blue: {
    700: "#026aa2",
  },
  error: {
    50: "#fef3f2",
    500: "#f04438",
    700: "#b42318",
  },
};

export const LISTING_CARD_LIMIT = {
  rfq: 18,
};
